<template>
  <div>
    <b-modal
      id="modal-soporte-evidencia"
      ref="refModalSoporteEvidencia"
      v-model="dialog"
      title="Cargar evidencias"
      size="lg"
      no-close-on-backdrop
      :hide-footer="true"
      no-close-on-esc
      hide-header-close
    >
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <span class="align-middle ml-50">Cargar evidencias</span>
          </b-card-title>
        </b-card-header>
        <b-card-body>

          <b-form
            enctype="multipart/form-data"
            @submit="formSubmit"
          >
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Título:"
                  label-for="input-1"
                >
                  <b-form-input
                    v-model="form.nombre"
                    placeholder="Nombre de la evidencia"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col md="9">
                <b-form-file
                  id="myFile"
                  v-model="modelFile"
                  :browse-text="'Seleccionar archivo'"
                  :placeholder="'No se eligió archivo'"
                  required
                  @change="onChange"
                >
                  <template
                    slot="file-name"
                    slot-scope="{ names }"
                  >
                    <b-badge variant="primary">
                      {{ names[0] }}
                    </b-badge>
                    <b-badge
                      v-if="names.length > 1"
                      variant="dark"
                      class="ml-1"
                    >
                      + {{ names.length - 1 }} More files
                    </b-badge>
                  </template>
                </b-form-file>
              </b-col>
              <b-col md="3">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  type="submit"
                >
                  <feather-icon
                    icon="SaveIcon"
                    size="15"
                    class="mr-1"
                  />Guardar
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </b-card-body>
      </b-card>

      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Lista de evidencias</span>
          </b-card-title>
        </b-card-header>

        <b-table
          striped
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
          :busy="isBusy"
          :empty-text="'No hay registros para mostrar'"
          show-empty
          head-variant="dark"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(actions)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              class="text-decoration-none mr-1"
              :href="row.item.archivo"
              :target="'_blank'"
            >
              ver
              <feather-icon
                :id="`invoice-row-5-preview-icon`"
                icon="DownloadIcon"
                size="16"
              />
            </b-button>

            <b-button
              size="sm"
              variant="outline-danger"
              class="text-decoration-none text-danger"
              @click="eliminarEvidencia(row.item, row.index)"
            >
              eliminar
              <feather-icon
                :id="`invoice-row-5-preview-icon`"
                icon="Trash2Icon"
                size="16"
              />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li
                  v-for="(value, key) in row.item"
                  :key="key"
                >
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-card>
      <b-button
        variant="outline-secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="close"
      >
        Cerrar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  onUnmounted, ref, toRefs, watch,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormGroup, BTable, BButton, BFormFile, BBadge, BForm, BCardHeader, BCardTitle, BCardBody, BFormInput, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import evidenciaNutricionStoreModule from './evidenciaNutricionStoreModule'

export default {
  components: {
    BCard, BRow, BCol, BFormGroup, BTable, BButton, BFormFile, BBadge, BForm, BCardHeader, BCardTitle, BCardBody, BFormInput, BSpinner,
  },
  props: {
    editedItem: {
      type: Object,
      default() {
        return {}
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelFile: null,
      form: {
        nombre: null,
        servidor_publico_id: null,
      },
      blankForm: {
        nombre: null,
        descripcion: null,
        servidor_publico_id: null,
      },
      anexos: [],
      showModal: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  methods: {
    eliminarEvidencia(item, index) {
      this.$swal({
        title: '¿Estas seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, borrar',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const nutricionId = this.editedItem.id
          const evidenciaId = item.id
          store.dispatch('evidencia-nutricion/deleteEvidencia', { nutricionId, evidenciaId })
            .then(response => {
              this.items.splice(index, 1)
              this.$swal({
                icon: 'success',
                title: 'Eliminado!',
                text: `Tu archivo "${response.data.nombre}" ha sido eliminado.`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          //   // fin store
        }
      })
    },
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.file = e.target.files[0]
    },
    formSubmit(e) {
      e.preventDefault()

      const data = new FormData()
      data.append('archivo', this.file)
      data.append('nombre', this.form.nombre)
      // Esto debe guardarse desde el backend
      data.append('servidor_publico_id', 1)

      const data2 = {
        id: this.editedItem.id,
        data,
      }
      store.dispatch('evidencia-nutricion/addEvidencia', data2)
        .then(response => {
          document.getElementById('myFile').value = ''
          this.file = null
          this.items = response.data
          this.form = { ...this.blankForm }
          this.modelFile = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Archivo cargado exitosamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Falló la carga',
              icon: 'XOctagonIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup(props) {
    const EVIDENCIA_APP_STORE_MODULE_NAME = 'evidencia-nutricion'

    // Register module
    if (!store.hasModule(EVIDENCIA_APP_STORE_MODULE_NAME)) store.registerModule(EVIDENCIA_APP_STORE_MODULE_NAME, evidenciaNutricionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVIDENCIA_APP_STORE_MODULE_NAME)) store.unregisterModule(EVIDENCIA_APP_STORE_MODULE_NAME)
    })

    const { editedItem } = toRefs(props)
    const dialog = ref(false)
    const isBusy = ref(true)
    const items = ref([])
    const fields = [
      { key: 'nombre', sortable: true, sortDirection: 'desc' },
      { key: 'actions', label: 'Acción' },
    ]
    watch(editedItem, () => {
      store.dispatch('evidencia-nutricion/fetchEvidencias', editedItem.value)
        .then(response => {
          items.value = response.data.data
          isBusy.value = false
        })
    })

    const close = () => {
      dialog.value = false
      isBusy.value = true
    }
    watch(dialog, () => {
      // eslint-disable-next-line no-unused-expressions
      dialog.value || close()
    })

    return {
      dialog,
      close,
      items,
      fields,
      isBusy,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
