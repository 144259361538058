import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // index
    getNutricion(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/nutricion', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // store
    addRegistroNutricion(ctx, nutricionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-social/nutricion', nutricionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // update
    updateNutricion(ctx, soporteData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-social/nutricion/${soporteData.id}`, soporteData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // delete
    deleteNutricion(ctx, visitaData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-social/nutricion/${visitaData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
