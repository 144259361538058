import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // index
    fetchEvidencias(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/nutricion/${id}/evidencias`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // store
    addEvidencia(ctx, nutricionaData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/nutricion/${nutricionaData.id}/evidencias`, nutricionaData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // delete
    deleteEvidencia(ctx, { nutricionId, evidenciaId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-social/nutricion/${nutricionId}/evidencias/${evidenciaId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
