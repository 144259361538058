import { render, staticRenderFns } from "./NutricionEvidenciasModal.vue?vue&type=template&id=44234594&scoped=true&"
import script from "./NutricionEvidenciasModal.vue?vue&type=script&lang=js&"
export * from "./NutricionEvidenciasModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44234594",
  null
  
)

export default component.exports