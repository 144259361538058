<template>
  <b-modal
    id="modal_add_nutricion"
    v-model="dialog"
    :title="vamosCrear ? 'Registrar nuevo' : 'Editar'"
    title-class="text-white"
    ok-only
    size="lg"
    :header-bg-variant="vamosCrear ? 'primary' : 'warning'"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <validation-provider
          #default="validationContext"
          name="Motivo"
          rules="required"
        >
          <b-form-group
            label="Motivo"
            label-for="servicio"
          >
            <b-form-textarea
              id="textarea"
              v-model="editedItem.motivo"
              placeholder="Describir motivo...."
              rows="3"
              max-rows="6"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="Motivo"
          rules="required"
        >
          <b-form-group
            label="Detalles"
            label-for="servicio"
          >
            <b-form-textarea
              id="textarea"
              v-model="editedItem.detalles"
              placeholder="Escribir los detalles..."
              rows="3"
              max-rows="6"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="Conclusiones"
          rules="required"
        >
          <b-form-group
            label="Conclusiones"
            label-for="servicio"
          >
            <b-form-textarea
              id="textarea"
              v-model="editedItem.conclusiones"
              placeholder="Conclusiones..."
              rows="3"
              max-rows="6"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex justify-content-between ">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="closeModal"
          >
            <feather-icon
              icon="XIcon"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="vamosCrear ? 'primary' : 'warning'"
            type="submit"
          >
            <feather-icon
              :icon="vamosCrear ? 'SaveIcon' : 'RefreshCcwIcon'"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">{{ vamosCrear ? 'Guardar' : 'Actualizar' }}</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import store from '@/store'
import {
  BButton, BForm, BFormGroup, BFormInvalidFeedback, BFormTextarea,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    vamosCrear: {
      type: Boolean,
      default: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      avatarText,
      required,
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:dialog', false)
    },
    onSubmit() {
      if (this.vamosCrear) {
        this.createNutricion()
      } else {
        this.updateEmergencia()
      }
    },
    createNutricion() {
      store.dispatch('comedor-nutricion/addRegistroNutricion', this.editedItem)
        .then(() => {
          this.$emit('actualizar-tabla')
          this.toastSuccess()
          this.closeModal()
        }).catch(() => {
          this.toastError()
        })
    },
    updateEmergencia() {
      store.dispatch('comedor-nutricion/updateNutricion', this.editedItem)
        .then(() => {
          this.$emit('actualizar-tabla')
          this.toastSuccess()
          this.closeModal()
        }).catch(() => {
          this.toastError()
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>

<style lang="scss" scoped>
</style>
